import { dBenefit } from 'core/types/benefit';

export const BenefitList: dBenefit[] = [
  {
    id: 0,
    from: '2023/04/01',
    to: '2023/06/05',
    enterprise: '期間外の特典',
    service_name: '期間外の特典',
    detail: 'これは表示されないよ',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/img-dummy-benefit.png',
    link: '#',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 19,
    from: '2024/04/01',
    to: '2024/09/30',
    enterprise: 'ソニー損保',
    service_name: '抽選キャンペーン',
    summary: '新規ご成約の方に抽選でプレゼント！',
    detail:
      '【キャンペーン期間：2024年9月30日まで】\nソニー損保の保険料は「走る分だけ」！\n予想年間走行距離に応じて保険料を算出するので、休日だけお車を使用するという方、最近お車に乗る機会が少なくなった方には特におすすめです！\n\nナイル株式会社の経営方針により損害保険会社を選定し、ご案内しております。\n\n※特典は、「抽選に応募できる」ことを指します。\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/ソニー損保_20240401.png',
    link: 'http://brg.sonysonpo.co.jp/10057702/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 2,
    from: '2023/01/01',
    to: '9999/12/31',
    enterprise: 'SBI損保',
    service_name: '自動車保険新規ご契約特典',
    summary: '豪華グッズが当たるキャンペーン中！',
    detail:
      'SBI損保の自動車保険が選ばれる3つの理由\nリーズナブルな保険料・業界最高基準※1のロードサービス・24時間365日事故受付\nインターネットからの新規お申し込みで保険料14,500円割引！※2\n\n\n引受保険会社：SBI損害保険株式会社(23-0072-12-001)\n※1 2023年2月SBI損保調べ。\n※2 ①インターネット割引(14,000円) ②証券不発行(500円)を適用した割引額です。\n月払の場合は年間14,520円(①14,040円②480円)となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/SBI損害保険.jpg',
    link: 'https://www.sbisonpo.co.jp/cha/nyle/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 3,
    from: '2024/04/01',
    to: '2025/3/31',
    enterprise: '楽天Car車検',
    service_name: '最大3,000pt',
    summary: 'エントリー＆車検実施でもらえる！',
    detail:
      '楽天Car車検でオトクに車検しよう！楽天ポイント3,000ポイントが獲得できて、さらにガソリン値引きなどのネット特典もアリ！口コミ評価平均も4.5/5.0だから安心して任せられます。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/楽天車検_0501~.jpg',
    link: 'https://car.rakuten.co.jp/shaken/campaign/carmo-kun/3000p_mar24/?scid=me_carmo_sha_guide',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 13,
    from: '2024/03/01',
    to: '2025/2/28',
    enterprise: '楽天Car洗車コーティング',
    service_name: '最大1,000pt',
    summary: 'コーティング実施でも楽天ポイント貯まってお得',
    detail:
      'カーコーティングの比較・来店予約サイト「楽天Car洗車・コーティング」を通して予約・実施することで、話題のコーティングもネット割価格でお得にでき、利用金額に応じた楽天ポイント（利用金額の1～5％分のポイント）も獲得できます。また、カルモ会員様ならさらに最大1,000ポイントを獲得（要エントリー、利用条件あり）できるので、話題のキーパーコーティングも大変お得に実施できます！',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/楽天car車検_洗車コーティング.jpg',
    link: 'https://car.rakuten.co.jp/carwash/campaign/carmo-kun/add1000_mar24/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 16,
    from: '2024/02/01',
    to: '2025/01/31',
    enterprise: '楽天Carキズ修理',
    service_name: '車のキズ修理',
    summary: '実施で楽天ポイント獲得',
    detail:
      '楽天Carキズ修理のサイトでは、画像診断実施中！キズ写真を添付するだけで修理の概算が分かります。さらに！予約店舗でキズ修理を実施すると、実施金額に応じた楽天ポイントとキャンペーンポイントが獲得できます。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/楽天Carキズ修理.jpg',
    link: 'https://car.rakuten.co.jp/repair/campaign/carmo-kun/1000p_feb24/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 4,
    from: '2023/12/01',
    to: '2024/08/31',
    enterprise: 'エビス光・電気',
    service_name: 'セットでお得',
    summary: '最大7万円分の特典あり',
    detail:
      'カルモくん契約者限定で、エビス光をご契約いただくと最大6万円相当の特典がもらえるキャンペーンが始まりました。さらに、エビス電気をセットでご契約いただくと最大7万円相当の特典がもらえます。※お住まいの地域や建物によっては導入出来ない場合があります。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/エビス電気.png',
    link: 'https://ebisuhikari.hp.peraichi.com/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 5,
    from: '2023/06/01',
    to: '2024/5/22',
    enterprise: 'akippa',
    service_name: 'カルモ会員様限定 10%OFFクーポン',
    summary: '簡単に駐車場予約＆おトクに利用',
    detail:
      'オンラインで駐車場予約ができるサービス「akippa」のカルモ契約者限定クーポンのご案内です。akippaでは全国に常時35,000件以上の豊富な駐車場が事前予約、事前決済できるアプリです。※2023年05月時点\n\nakippaの駐車場は空きスペースを有効活用しているため、コインパーキングより安価な料金設定でおトクです。行楽地やイベント会場での駐車場を探す手間が省け、ドライブがもっと楽しくなるakippaを、この機会にぜひご利用ください。\n\n ■ご利用上の注意\n・1日、15分単位両方の予約で使用出来ます。\n・利用回数は最大100回まで、最大適応日数は30日となります。\n・サービス料には適用されません。\n\n■クーポン発行の流れ\n・akippa未登録の方：ログイン画面より登録いただき、再度ログインしてください。自動的にクーポン取得となります。\n・akippa登録済みの方：URLへアクセスいただくだけでクーポン自動取得となります。\n（※ログイン情報を端末に保存していた場合）\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/akippa.png',
    link: 'https://www.akippa.com/?coupon_code=7fe92d93a75087d74cb31ea50e52d88b',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 6,
    from: '2023/07/01',
    to: '9999/12/31',
    enterprise: 'VEEMO',
    service_name: '初回登録で500pt',
    summary: 'カンタン、お得な駐車場検索アプリ',
    detail:
      'プロモーションコード：【carmo500】を入れてすぐ500ポイントゲット！さらに「駐車場レシート買取キャンペーン」応募でレシート1枚10VEEMOポイントのところ、カルモ契約者なら2倍の20VEEMOポイントもらえる！\n※リンク先はスマホやタブレットのアプリダウンロードページになります。パソコン等では開けませんので、予めご了承ください。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/VEEMO.png',
    link: 'https://app.veemo.jp/app_store',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 7,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'レジャパス！',
    service_name: 'プレミアムプラン',
    summary: '月額3,980円（税別）が初月無料！',
    detail:
      '全国200以上の観光施設を定額で利用できる「レジャパス」の特別特典。\n温泉・レジャー・エンタメなどで好きなだけ遊べて楽しみ放題、癒され放題。\nカルモ契約者なら、クーポンコードをコードを入れるだけで全てのプランが初月無料！\n\nクーポンコード：carmokun\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/レジャパス_プレミアム.png',
    link: 'https://lp.lejapass.com/2023main1/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 8,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'レジャパス！',
    service_name: 'スタンダードプラン',
    summary: '月額2,980円（税別）が初月無料！',
    detail:
      '全国200以上の観光施設を定額で利用できる「レジャパス」の特別特典。\n温泉・レジャー・エンタメなどで好きなだけ遊べて楽しみ放題、癒され放題。\nカルモ契約者なら、クーポンコードをコードを入れるだけで全てのプランが初月無料！\n\nクーポンコード：carmokun2\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/レジャパス_スタンダード.png',
    link: 'https://lp.lejapass.com/2023main1/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 21,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'レジャパス！',
    service_name: 'ライトプラン',
    summary: '月額1,980円（税別）が初月無料！',
    detail:
      '全国200以上の観光施設を定額で利用できる「レジャパス」の特別特典。\n温泉・レジャー・エンタメなどで好きなだけ遊べて楽しみ放題、癒され放題。\nカルモ契約者なら、クーポンコードをコードを入れるだけで全てのプランが初月無料！\n\nクーポンコード：carmokun3\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/レジャパス_ライト.png',
    link: 'https://lp.lejapass.com/2023main1/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 20,
    from: '2023/10/01',
    to: '2024/12/31',
    enterprise: 'セイビー',
    service_name: '限定クーポン発行中',
    summary: '出張バッテリー交換1,000円オフ',
    detail:
      'クルマのバッテリー交換を出張で！作業実績60,000件以上！輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2024年12月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_バッテリー交換.png',
    link: 'https://seibii.co.jp/services/battery',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 21,
    from: '2023/10/01',
    to: '2024/12/31',
    enterprise: 'セイビー',
    service_name: '限定クーポン発行中',
    summary: '出張ドラレコ取り付け1,000円オフ',
    detail:
      'ドライブレコーダーを出張でお取り付け！作業実績60,000件以上！配線は見えない形でプロがキレイにお取り付けいたします。輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2024年12月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_前後ドラレコ.png',
    link: 'https://seibii.co.jp/services/driverecorder',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 22,
    from: '2023/10/01',
    to: '2024/12/31',
    enterprise: 'セイビー',
    service_name: '限定クーポン発行中',
    summary: 'スタッドレスタイヤ履き替え1回利用1000円OFF',
    detail:
      'クルマのスタッドレスタイヤ履き替えを出張で自宅で交換可能！作業実績60,000件以上！輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2024年12月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_スタッドレスタイヤ.png',
    link: 'https://seibii.co.jp/services/tyre_change_w_wheel',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 14,
    from: '2024/09/25',
    to: '2025/01/07',
    enterprise: 'アソビュー',
    service_name: '3%オフクーポン',
    summary: '何回でも遊びの予約をおトクに',
    detail:
      '日本全国の遊びを便利でお得に予約できるサービス「アソビュー」で使えるクーポンです！\n夏に人気のSUPやダイビング、スパや日帰り温泉など、遊びの種類620以上！施設数はなんと10,470施設以上のラインナップ。\n日々の遊びを考える際にはぜひ簡単に検索、選ぶ、予約ができるアソビューをご活用ください。\nクーポンコード：Wi2J3\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/アソビュー.png',
    link: 'https://www.asoview.com/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 15,
    from: '2023/08/01',
    to: '2099/12/31',
    enterprise: 'オリックスレンタカー',
    service_name: '特割15',
    summary: 'お得なレンタカープラン',
    detail:
      'オリックスレンタカーでは、おトクにマイカー定額カルモくん会員さま限定で、通常のご利用に比べてお得にご利用いただけるレンタカープランをご用意いたしました。ぜひオリックスレンタカーをご利用ください。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/オリックスレンタカー.jpg',
    link: 'https://car.orix.co.jp/campaigns_page/?campk=1893',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 17,
    from: '2023/08/17',
    to: '2099/12/31',
    enterprise: 'subsclife(ｻﾌﾞｽｸﾗｲﾌ)',
    service_name: '8%OFFクーポン',
    summary: '人気のデザイナーズ家具・ブランド家電をお得に利用',
    detail:
      'お支払い総額が定価を超えない、新品家具・家電のサブスクサービス「subsclife（サブスクライフ）」のカルモ契約者限定クーポンのご案内です。\n\n\nライフスタイルに合わせて、月額500円から始められる家具・家電のサブスクサービス\n\n■subsclifeの3つのポイント\n・お支払い総額が定価を超えない\n・新品デザイナーズ家具・ブランド家電を一点から利用可能\n・月額払いでお財布に優しく、購入も可能\n\n月額払いで理想のお部屋づくりをもっとお手軽に！\nsubsclifeを、この機会にぜひご利用ください。\n\nクーポンコード：carmospC2308\n\n※クーポンご利用上の注意\n・月額の合計が2,000円以上のお申込にご利用可能\n・1アカウント1回まで\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/サブスクライフ.png',
    link: 'https://subsclife.com/?utm_source=other&utm_medium=other&utm_campaign=2308-app-nyl',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 18,
    from: '2023/09/14',
    to: '2099/12/31',
    enterprise: '子供靴のサブスク「Kutoon」',
    service_name: '初月無料！',
    summary: 'カルモ契約者はさらに2ヶ月目も30%off',
    detail:
      'すぐにサイズが変わる子供靴の悩み、Kutoonの定額レンタルで解消しませんか？\n\n\nラインナップは有名メーカの靴が中心！\n\n■Kutoonの3つのポイント\n①家にいながら毎月新しい靴が履ける\n②下駄箱に靴が増えない\n③こども靴を洗わなくてよくなる\n\n■選べるプランは3種類！初月無料＆カルモ契約者は2ヶ月目も30%オフ！\n・まずは気軽に、手元に1足プラン　月額2,480円\n・一番人気の、手元に2足プラン　月額2,980円\n・兄弟姉妹でつかえる、手元に3足プラン　月額3,700円\n\n■クーポン使用方法\n①気になるプランの「無料で試す」をクリック\n②会員登録をする\n③お申込み画面でクーポンコードを入れる\nクーポンコード：car-mo30\n\n※ご利用上の注意\n・退会はいつでも可能です。\n・カルモ契約者の2ヶ月目30%オフ特典を利用する際には必ずクーポンコードを記入してください。\n・初月無料キャンペーンはみなさま自動的に適応されます。\n・初月無料キャンペーンは、やむを得ない事情により中止・変更となる場合があります。\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/Kutoon.png',
    link: 'https://kutoon.net/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 23,
    from: '2023/11/1',
    to: '2099/12/31',
    enterprise: 'ハート引越センター',
    service_name: '引越料金30%OFF！',
    summary: '割引など各種特典をご用意！',
    detail:
      '引越し料金の見積もり、単身引越しのご相談ならハート引越センター　プロの引越し業者にお任せ\n\n\n北海道から沖縄まで全国直営、独自の物流システムで近距離はもちろん長距離引越も安心、納得のサービスをご提供いたします。荷造り荷解き、電気工事やハウスクリーニングなどもお気軽にご相談ください\n\n■　カルモ契約者特典\n①基本料金より30%オフ！※繁忙期間3/15~4/10は除く\n②梱包資材各種プレゼント（ダンボール単身10枚、家族50枚・ガムテープ必要数・ハンガーBOX必要数レンタル無料）\n\n■　クーポン使用方法は２つ\n①ハート引越センター「0120-810-321」へお電話のうえ「定額カルモくんの契約者」とお伝えください。\n②専用サイトからWEB見積依頼（必ず、下記の「特典をつかう」ボタンからお申し込みください）\n\n※ご利用上の注意\n30%オフ特典を利用する際には必ずクーポン使用方法に従ってください。\nキャンペーンは、やむを得ない事情により中止・変更となる場合があります。\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/ハート引っ越しセンター.jpg',
    link: 'https://www.hikkoshi8100.com/?media_type=165',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 24,
    from: '2023/11/28',
    to: '2099/12/31',
    enterprise: 'アクティビティジャパン',
    service_name: '限定クーポン発行中',
    summary: 'アクティビティ、体験の予約をおトクに！',
    detail:
      '日本全国のアクティビティ、体験を便利でお得に予約できるサービス「アクティビティジャパン」で使えるクーポンです！\n\n\n夏に人気のシュノーケリングやSUP、ダイビングなどアウトドアアクティビティ・遊び・体験・レジャーを日本全国400種目、18,000プラン以上の中から格安予約！\nおでかけにはぜひ簡単に検索、予約ができるアクティビティジャパンをご活用ください。\n\nクーポンコード：CAKN\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/アクティビティジャパン.jpg',
    link: 'https://activityjapan.com/',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 25,
    from: '2024/05/01',
    to: '2099/12/31',
    enterprise: '',
    service_name: 'お部屋探しは「ヘヤギメ！」',
    summary: '仲介手数料50%OFF',
    detail:
      '■カルモ契約者特典\n《仲介手数料》通常賃料の1ヶ月分＋消費税のところ、ヘヤギメ！にてご成約をいただくと50％OFF！\n\n■特典使用方法\n①専用サイト『お部屋探しリクエストフォーム』からお問い合わせいただく\n（必ず、下記の『お部屋探しリクエストフォーム』ボタンからお問い合わせください）\n②『お電話』または『メール』にてお問い合わせいただく\n・TEL：0120-542-899\n・MAIL：rm@sfit.co.jp\n\n■対象エリア\n東京・神奈川・埼玉・千葉・大阪・兵庫・愛知・福岡\n\n■注意事項\n・お問い合わせ時に『定額カルモくんのご契約者様』である旨をお伝えください。\n　※お伝えいただけない場合、特典の対象外となります。\n・上記お問い合わせ方法にてご依頼をいただいた場合に限り、特典が適用されます。\n・その他の特典との併用はできません。\n\n------------\n・急遽転勤が決まった\n・はじめての一人暮らしで不安・・・\n・結婚や更新を控えていて、引っ越しを検討している　etc...\nお引越をご検討されている皆様、お部屋探しは『ヘヤギメ！』にお任せ下さい！\n\nお客様からいただいたご希望条件を元に、専門スタッフが物件情報をお届け致します。\nヘヤギメ！なら市場に出ているほぼ全ての物件からご紹介ができるので、\n複数件の不動産会社をまわるお手間が省け、スムーズなお部屋探しが可能です。\n――――――――――\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/logo_heyagime.png',
    link: 'https://business.form-mailer.jp/fms/fc89bb25242038',
    link_text: 'お部屋探しリクエストフォーム',
  },
  {
    id: 26,
    from: '2024/06/03',
    to: '2099/12/31',
    enterprise: 'おそうじ本舗',
    service_name: 'カルモ会員様特別ご優待価格',
    summary: 'ハウスクリーニングはおそうじ本舗におまかせ！',
    detail:
      'エアコンの嫌な臭い、キッチンの頑固な油汚れやこげつき、浴室のしつこい水アカ・カビなど……\n気になる場所のおそうじは、試せば分かるプロの技術力｢おそうじ本舗｣へお任せ下さい！\n\n■優待内容：\nエアコンクリーニング：おそうじ本舗通常価格より5％OFF\nその他メニュー（オプションを含む）：おそうじ本舗通常価格より10％OFF\n\n■特典使用方法\n専用サイトからWEB見積依頼（必ず、下記の「特典をつかう」ボタンからお申し込みください）\n\n\n■クリーニング全般について注意事項\n※電気・水道・ガス・洗浄スペースをお借りします。\n※汚れとサービス範囲に応じて別途お見積りをさせて頂く場合がございます。\n※汚れの状況により、完全に除去できない場合がございます。\n※年式・設置状況によって対応できない場合がございます。\n※その他の割引サービスやキャンペーンとの併用はできません。\n※作業店舗の指定はできかねます。あらかじめご了承ください。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/%E3%81%8A%E3%81%9D%E3%81%86%E3%81%97%E3%82%99%E6%9C%AC%E8%88%97_%E9%80%9A%E5%B9%B4%E3%83%8F%E3%82%99%E3%83%8A%E3%83%BC_330x220.jpg',
    link: 'https://partner.racn.jp/estimate/nyle/0001/lp',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 27,
    from: '2024/07/01',
    to: '2099/12/31',
    enterprise: 'キッズライン',
    service_name: 'KIDSLINE（キッズライン）/新規利用限定3,000円OFFクーポン',
    summary: 'ご予算や依頼内容に合わせてサポーターが選べるベビーシッター・家事代行のマッチングサービス。',
    detail:
      'キッズラインが一人ひとり面談を行った信頼のおけるサポーターに、 登録料・月会費一切なしでご予算に合わせて依頼いただけます。 サポーターの写真や利用者の口コミを公開しており、スマホ・PCで24時間育児の頼れるパートナーに予約することが可能です。\n\n■優待内容：\n新規利用限定3,000円OFFクーポン\n\n■特典使用方法\nお申込み画面でクーポンコードを入れる\nクーポンコード：carmo_3000',
    img_url: 'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/kidsline.png',
    link: 'https://kidsline.me/information/carmo-kun',
    link_text: '特典をつかう(サイトへ行く)',
  },
  {
    id: 28,
    from: '2024/07/01',
    to: '2099/12/31',
    enterprise: 'CLAS',
    service_name: '2,500ptプレゼント',
    summary: '定額カルモくんご契約者様専用ページから新規登録でもらえる！',
    detail:
      '【累計4万人利用】CLASは、家具や家電を1点から月額制でレンタルできるサブスクリプションサービスです。ライフスタイルに合わせて、必要なときに必要なものをレンタル、不要になったら返却・交換することで、自由で手軽に気になるアイテムを利用できます。※2024年3月時点\n\n※1ポイント1円で、CLASの商品レンタル月額費用に利用できます。\n※現在会員の方や、過去に登録されたことがある方は対象外となります。\n※キャンペーンページから新規会員登録された方のみ適用されます。',
    img_url: 'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/clas.jpg',
    link: 'https://clas.style/article/1909?utm_campaign=グループ招待%3A定額カルモくん会員様限定（2224）&bannerOff=true',
    link_text: '特典をつかう(サイトへ行く)',
  },
];
