import { ManualTitle } from '../../component/ManualTitle';
import ManualPageList from '../../component/ManualPageList';
import imgReinstatement01 from '../../common/img/reinstatement/img-reinstatement-01.png';
import imgReinstatement01webp from '../../common/img/reinstatement/img-reinstatement-01.webp';
import imgReinstatement02 from '../../common/img/reinstatement/img-reinstatement-02.png';
import imgReinstatement02webp from '../../common/img/reinstatement/img-reinstatement-02.webp';
import imgReinstatement03 from '../../common/img/reinstatement/img-reinstatement-03.png';
import imgReinstatement03webp from '../../common/img/reinstatement/img-reinstatement-03.webp';
import imgReinstatement04 from '../../common/img/reinstatement/img-reinstatement-04.png';
import imgReinstatement04webp from '../../common/img/reinstatement/img-reinstatement-04.webp';
import imgReinstatement05 from '../../common/img/reinstatement/img-reinstatement-05.png';
import imgReinstatement05webp from '../../common/img/reinstatement/img-reinstatement-05.webp';
import imgReinstatement06 from '../../common/img/reinstatement/img-reinstatement-06.png';
import imgReinstatement06webp from '../../common/img/reinstatement/img-reinstatement-06.webp';
import imgReinstatement07 from '../../common/img/reinstatement/img-reinstatement-07.png';
import imgReinstatement07webp from '../../common/img/reinstatement/img-reinstatement-07.webp';
import imgReinstatement08 from '../../common/img/reinstatement/img-reinstatement-08.png';
import imgReinstatement08webp from '../../common/img/reinstatement/img-reinstatement-08.webp';
import imgReinstatement09 from '../../common/img/reinstatement/img-reinstatement-09.png';
import imgReinstatement09webp from '../../common/img/reinstatement/img-reinstatement-09.webp';
import imgReinstatement10 from '../../common/img/reinstatement/img-reinstatement-10.png';
import imgReinstatement10webp from '../../common/img/reinstatement/img-reinstatement-10.webp';
import imgReinstatement11 from '../../common/img/reinstatement/img-reinstatement-11.png';
import imgReinstatement11webp from '../../common/img/reinstatement/img-reinstatement-11.webp';
import imgReinstatement12 from '../../common/img/reinstatement/img-reinstatement-12.png';
import imgReinstatement12webp from '../../common/img/reinstatement/img-reinstatement-12.webp';
import imgReinstatement13 from '../../common/img/reinstatement/img-reinstatement-13.png';
import imgReinstatement13webp from '../../common/img/reinstatement/img-reinstatement-13.webp';

export const ManualReinstatement007: React.FC = () => {
  return (
    <>
      <ManualTitle
        id="manual-007"
        SubTitle="精算金（原状回復費用等）"
      ></ManualTitle>
      <section className="section-common">
        <div className="container">
          <h4 className="common-section-header has-text-centered has-text-weight-bold">
            精算金（原状回復費用等）について
          </h4>
          <div className="inner">
            <p>
              お車の返却時は、定額カルモくんにて、財団法人日本自動車査定協会またはその他公正な機関が定める基準によりお車の査定を行います。規定の金額を超える損害が発生した場合は、精算金をお支払いいただきます。
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            1.精算金が発生する事例
          </h4>
          <div className="inner">
            <ul>
              <li>
                ・1cm以上のキズ及びへこみ等外装の損傷、運転に損傷、運転に支障をきたす損傷（ドアミラー、ワイパー、ランプ類等の欠落及び損傷等）
              </li>
              <li>
                ・内装の損傷（シートのへたり等は除く）、タバコの焦げ痕、タバコ臭、ペット臭等
              </li>
              <li>
                ・事故修復歴（車両の骨格部分の修復・交換など）による価値の減少等
              </li>
              <li>・改造・模様替え等による価値の減少等</li>
            </ul>
            <p className="is-margin-top-7">
              上記の事例については、実際はさらに詳細な基準による判定がされます。例えば、1cm未満のキズであっても、同時にへこみがある場合は、費用が発生いたします。
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            2.精算金の算出基準
          </h4>
          <div className="inner">
            <p>
              上記の事例に基づき、以下のとおり算出します。
              <br />
              精算金1＝｛（内外装の損傷による減点（※1）×車両クラス係数×経過年係数（※2）＋商品価値減点（※3）｝×1,000円
              <br />
              ※1　みがき、クリーニングの減点は除く
              <br />
              ※2　車両クラス係数：車種により異なる
              <br />
              ※3　事故修復があることによる減点など、但し、走行距離による減点は除く
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            3.基準走行距離の超過による精算金2
          </h4>
          <div className="inner">
            <p>
              走行距離（1ヶ月あたり上限500kmまたは1,000km）を超過した場合は、走行距離1kmにつき8円(税別)の精算金をお支払いいただきます。
              <br />
              ※走行距離の上限はご契約内容により異なります
            </p>
            <p className="is-margin-top-7">
              基準走行距離＝経過月数×月額走行基準距離
              <br />
              （経過月数は1ヶ月単位とし、1ヶ月を満たない場合は切り上げて計算いたします）
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            精算金額の合計
          </h4>
          <div className="inner">
            <p>
              <span className="has-text-weight-bold">
                精算金額の合計額＝精算金１＋精算金２－免責金額
              </span>
              <br />
              ※免責金額は一律5万円を上限とする。
            </p>
          </div>
          <h4 className="common-section-header has-text-centered has-text-weight-bold is-margin-top-7">
            【補足説明】同意書記載の賠償金について
          </h4>
          <div className="inner">
            <p>
              同意書に記載のあります「賠償金が発生する事例」に関しまして、画像による実例を用いて一部補足説明いたします。
            </p>
            <p className="is-margin-top-7">
              また、本補足に記載した内外装の損傷に伴って発生する賠償金額は、金額感を把握いただくための概算でございます。損傷の状態により、金額は増減いたします。
            </p>
            <h5 className="common-text-header has-text-weight-bold">
              名刺大程度まで
            </h5>
            <div className="is-flex is-mobile is-margin-top-5 has-gap-10">
              <picture>
                <source src={imgReinstatement01webp} type="image/webp" />
                <source src={imgReinstatement01} />
                <img
                  src={imgReinstatement01}
                  alt=""
                  loading="lazy"
                  width="160"
                  height="120"
                />
              </picture>
              <picture>
                <source src={imgReinstatement02webp} type="image/webp" />
                <source src={imgReinstatement02} />
                <img
                  src={imgReinstatement02}
                  alt=""
                  loading="lazy"
                  width="160"
                  height="120"
                />
              </picture>
            </div>
            <p className="has-text-weight-bold has-text-centered is-margin-top-3">
              約10,000円
            </p>
            <h5 className="common-text-header has-text-weight-bold is-margin-top-7">
              A4サイズに満たない程度
            </h5>
            <div className="is-flex is-mobile is-margin-top-5 has-gap-10">
              <picture>
                <source src={imgReinstatement03webp} type="image/webp" />
                <source src={imgReinstatement03} />
                <img
                  src={imgReinstatement03}
                  alt=""
                  loading="lazy"
                  width="146"
                  height="110"
                />
              </picture>
              <picture>
                <source src={imgReinstatement04webp} type="image/webp" />
                <source src={imgReinstatement04} />
                <img
                  src={imgReinstatement04}
                  alt=""
                  loading="lazy"
                  width="170"
                  height="110"
                />
              </picture>
            </div>
            <p className="has-text-weight-bold has-text-centered is-margin-top-3">
              約20,000円〜約50,000円
            </p>
            <h5 className="common-text-header has-text-weight-bold is-margin-top-7">
              A4サイズを超えるもの
            </h5>
            <div className="is-flex is-mobile is-margin-top-5 has-gap-10">
              <picture>
                <source src={imgReinstatement05webp} type="image/webp" />
                <source src={imgReinstatement05} />
                <img
                  src={imgReinstatement05}
                  alt=""
                  loading="lazy"
                  width="153"
                  height="90"
                />
              </picture>
              <picture>
                <source src={imgReinstatement06webp} type="image/webp" />
                <source src={imgReinstatement06} />
                <img
                  src={imgReinstatement06}
                  alt=""
                  loading="lazy"
                  width="160"
                  height="90"
                />
              </picture>
            </div>
            <p className="has-text-weight-bold has-text-centered is-margin-top-3">
              約50,000円から程度に応じて
            </p>
            <h5 className="common-text-header has-text-weight-bold is-margin-top-7">
              ガラスの損傷
            </h5>
            <p className="has-text-weight-bold has-text-centered is-margin-top-7">
              飛石等による小傷、修理あと
            </p>
            <div className="is-flex is-mobile is-margin-top-3 has-gap-10">
              <picture>
                <source src={imgReinstatement07webp} type="image/webp" />
                <source src={imgReinstatement07} />
                <img
                  src={imgReinstatement07}
                  alt=""
                  loading="lazy"
                  width="165"
                  height="120"
                />
              </picture>
              <picture>
                <source src={imgReinstatement08webp} type="image/webp" />
                <source src={imgReinstatement08} />
                <img
                  src={imgReinstatement08}
                  alt=""
                  loading="lazy"
                  width="165"
                  height="120"
                />
              </picture>
            </div>
            <p className="has-text-weight-bold has-text-centered is-margin-top-3">
              約20,000円
            </p>
            <p className="has-text-weight-bold has-text-centered is-margin-top-7">
              交換を要するひび割れ、亀裂
            </p>
            <div className="is-margin-top-3 has-text-centered">
              <picture>
                <source src={imgReinstatement09webp} type="image/webp" />
                <source src={imgReinstatement09} />
                <img
                  src={imgReinstatement09}
                  alt=""
                  loading="lazy"
                  width="200"
                  height="120"
                />
              </picture>
            </div>
            <p className="has-text-centered is-margin-top-3">
              <span className="has-text-weight-bold ">
                約80,000円〜約180,000円
              </span>
              <br />
              ※自動ブレーキ付きの場合は再調整費を含む
            </p>
            <h5 className="common-text-header has-text-weight-bold is-margin-top-7">
              内装の損傷
            </h5>
            <div className="is-flex is-mobile is-margin-top-7 has-gap-10 has-spacearound has-text-centered">
              <div>
                <p className="has-text-weight-bold">シートなどの焦げ</p>
                <picture>
                  <source src={imgReinstatement10webp} type="image/webp" />
                  <source src={imgReinstatement10} />
                  <img
                    className="is-margin-top-3"
                    src={imgReinstatement10}
                    alt=""
                    loading="lazy"
                    width="90"
                    height="120"
                  />
                </picture>
                <p className="has-text-weight-bold is-margin-top-3">
                  約12,000円
                </p>
              </div>
              <div>
                <p className="has-text-weight-bold">内張等へのヤニ付着</p>
                <picture>
                  <source src={imgReinstatement11webp} type="image/webp" />
                  <source src={imgReinstatement11} />
                  <img
                    className="is-margin-top-3"
                    src={imgReinstatement11}
                    alt=""
                    loading="lazy"
                    width="160"
                    height="120"
                  />
                </picture>
                <p className="has-text-weight-bold is-margin-top-3">
                  約45,000円
                </p>
              </div>
            </div>
            <div className="is-flex is-mobile is-margin-top-7 has-gap-10 has-spacearound has-text-centered">
              <div>
                <p className="has-text-weight-bold">シール等のはがしあと</p>
                <picture>
                  <source src={imgReinstatement12webp} type="image/webp" />
                  <source src={imgReinstatement12} />
                  <img
                    className="is-margin-top-3"
                    src={imgReinstatement12}
                    alt=""
                    loading="lazy"
                    width="90"
                    height="120"
                  />
                </picture>
                <p className="has-text-weight-bold is-margin-top-3">
                  約12,000円
                </p>
              </div>
              <div>
                <p className="has-text-weight-bold">
                  異臭
                  <br />
                  （タバコ・ペット等）
                </p>
                <picture>
                  <source src={imgReinstatement13webp} type="image/webp" />
                  <source src={imgReinstatement13} />
                  <img
                    className="is-margin-top-3"
                    src={imgReinstatement13}
                    alt=""
                    loading="lazy"
                    width="120"
                    height="110"
                  />
                </picture>
                <p className="has-text-weight-bold is-margin-top-3">
                  約45,000円
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ManualPageList id="manual-007"></ManualPageList>
    </>
  );
};
